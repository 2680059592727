import React, {useEffect, useRef, useState } from 'react';
import {Toast} from "primereact/toast";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {FileUpload} from "primereact/fileupload";
import {Legend} from "@amcharts/amcharts4/.internal/charts/Legend";
import {Divider} from "primereact/divider";
import {HTMLLegend} from "./htmllegeng/HTMLLegend";
import {Slider} from "primereact/slider";
import {ProgressBar} from "primereact/progressbar";
import {Button} from "primereact/button";
import {Tag} from "primereact/tag";
import { jsPanel } from 'jspanel4/es6module/jspanel';
import 'jspanel4/es6module/extensions/modal/jspanel.modal';
import 'jspanel4/dist/jspanel.min.css';
import * as ReactDOM from "react-dom";
import {Map} from "./leaflet/Map";
import { TreeTable } from 'primereact/treetable';
import NodeService from '../../../service/NodeService';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import {InputText} from "primereact/inputtext";
import ReactHtmlParser from "react-html-parser";
import QvantumService from "../../service/QvantumService";
import {Menubar} from "primereact/menubar";
import {SplitButton} from "primereact/splitbutton";
import {Toolbar} from "primereact/toolbar";
import {OverlayPanel} from "primereact/overlaypanel";
import ProductService from "../../../service/ProductService";
import {PickList} from "primereact/picklist";
import {MultiSelect} from "primereact/multiselect";
import {BlockUI} from "primereact/blockui";

export const GlowGlobe = (props) => {


    const [layers, setLayers] = useState(null);
    const [expandedLayers, setExpandedLayers] = useState([]);
    const [selectedLayers, setSelectedLayers] = useState([]);
    const [selectedNodeKeys3, setSelectedNodeKeys3] = useState([]);
    const [nodes, setNodes] = useState([]);
    const [action, setAction] = useState(null);

    const [hideLayers,setHideLayers] = useState(false);
    const [hideMap,setHideMap] = useState(false);
    const [hideVariables,setHideVariables] = useState(false);

    const [displayDialog, setDisplayDialog] = useState(false);
    const [totalSize, setTotalSize] = useState(0);
    const [globalFilter1, setGlobalFilter1] = useState(null);


    const toast = useRef(null);
    const fileUploadRef = useRef(null);

    const [products, setProducts] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedCrops, setSelectedCrops] = useState(null);
    const [selectedVariable, setSelectedVariable] = useState(null);
    const [selectedGroupedCities, setSelectedGroupedCities] = useState(null);

    const productService = new ProductService();
    const op = useRef(null);
    const isMounted = useRef(false);

    const [source, setSource] = useState([]);
    const [target, setTarget] = useState([]);


    const sampleLayer =
        [
            {
                id:1,
                name:"MapSPAM Wheat 2010 Yield, rainfed (kg/ha)",
                reload:true,
                configuration:
                    {
                        type:"tile",
                        location:"https://gardian-png-catalog.s3.eu-central-1.amazonaws.com/spam2010V2r0_global_H_WHEA_R.tif/{z}/{x}/{y}.png",
                        opacity: 0.5,
                    },
                legend:
                    {
                        type:"continuous",
                        data:
                            {
                                minimum:0,
                                maximum:100,
                                minimumColor:"#F5DBCB",
                                maximumColor:"#ED7B84"
                            }
                    }
            },
            {
                id:2,
                name:"MapSPAM Wheat 2010 Harvested area, rainfed (ha)",
                reload:true,
                configuration:
                    {
                        type:"tile",
                        location:"https://gardian-png-catalog.s3.eu-central-1.amazonaws.com/spam2010V2r0_global_H_WHEA_R.tif/{z}/{x}/{y}.png",
                        opacity: 1.0,
                    },
                legend:
                    {
                        type:"continuous",
                        data:
                            {
                                minimum:0,
                                maximum:100,
                                minimumColor:"#F5DBCB",
                                maximumColor:"#ED7B84"
                            }
                    }
            },
            {
                id:3,
                name:"MapSPAM Wheat 2010 Production, rainfed (mt)",
                reload:true,
                configuration:
                    {
                        type:"tile",
                        location:"https://gardian-png-catalog.s3.eu-central-1.amazonaws.com/SPAM2005V3r2_global_A_TA_BANA_A.tif/{z}/{x}/{y}.png",
                        opacity: 1.0,
                    },
                legend:
                    {
                        type:"continuous",
                        data:
                            {
                                minimum:0,
                                maximum:100,
                                minimumColor:"#F5DBCB",
                                maximumColor:"#ED7B84"
                            }
                    }
            },
            {
                id:4,
                name:ReactHtmlParser("ISRIC SOiL Grids, Organic Carbon, (g kg<sup>-1</sup>)"),
                reload:true,
                configuration:
                    {
                        type:"tile",
                        location:"https://gardian-png-catalog.s3.eu-central-1.amazonaws.com/SPAM2005V3r2_global_A_TA_BANA_A.tif/{z}/{x}/{y}.png",
                        opacity: 1.0,
                    },
                legend:
                    {
                        type:"continuous",
                        data:
                            {
                                minimum:0,
                                maximum:100,
                                minimumColor:"#F5DBCB",
                                maximumColor:"#ED7B84"
                            }
                    }
            },
            {
                id:5,
                name:ReactHtmlParser("iSDAsoil, Phosphorus content (mg kg<sup>-1</sup>)"),
                reload:true,
                configuration:
                    {
                        type:"tile",
                        location:"https://gardian-png-catalog.s3.eu-central-1.amazonaws.com/SPAM2005V3r2_global_A_TA_BANA_A.tif/{z}/{x}/{y}.png",
                        opacity: 1.0,
                    },
                legend:
                    {
                        type:"continuous",
                        data:
                            {
                                minimum:0,
                                maximum:100,
                                minimumColor:"#F5DBCB",
                                maximumColor:"#ED7B84"
                            }
                    }
            },
            {
                id:6,
                name:"AfSIS Land Use",
                reload:true,
                configuration:
                    {
                        type:"tile",
                        location:"https://gardian-png-catalog.s3.eu-central-1.amazonaws.com/SPAM2005V3r2_global_A_TA_BANA_A.tif/{z}/{x}/{y}.png",
                        opacity: 1.0,
                    },
                legend:
                    {
                        type:"continuous",
                        data:
                            {
                                minimum:0,
                                maximum:100,
                                minimumColor:"#F5DBCB",
                                maximumColor:"#ED7B84"
                            }
                    }
            }
        ]

    //

    const items = [
        {
            label: 'MapSPAM',
            icon: 'pi pi-refresh'
        },
        {
            label: 'Delete',
            icon: 'pi pi-times'
        },
        {
            label: 'React Website',
            icon: 'pi pi-external-link',
            command: () => {
                window.location.href = 'https://reactjs.org/'
            }
        },
        {   label: 'Upload',
            icon: 'pi pi-upload',
            command: () => {
                window.location.hash = "/fileupload"
            }
        }
    ];

    useEffect(() => {
        if (isMounted.current && selectedProduct) {
            op.current.hide();
            toast.current.show({severity:'info', summary: 'Product Selected', detail: selectedProduct.name, life: 3000});
        }
    }, [selectedProduct]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const qvantumService = new QvantumService();
        qvantumService.getLayers().then(
            data =>{
                // setLayers(data);
            }
        )

        isMounted.current = true;
        productService.getProductsSmall().then(data => {
            setProducts(data);
        });

        productService.getProductsSmall().then(data => {
             //setSource(data);
        });

        const nodeservice = new NodeService();
        nodeservice.getTreeTableNodes().then(data => setNodes(data));
    }, []);

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
    }

    const onProductSelect = (e) => {
        setSelectedProduct(e.value);
    }

    const imageBody = (rowData) => {
        return rowData.description;
    }

    const priceBody = (rowData) => {
        return rowData.location;
    }


    const rowExpansionTemplate = (data) => {




        return (
            <div style={{backgroundColor:"white",padding:"10px 10px"}}>
                <h6>Legend:</h6>
                {
                    data.configuration.distribution === "continuous"?
                        <Legend data={data} className="legend-div"/>:
                        <HTMLLegend data={data} />

                }

                <Divider align="left">
                    <div className="p-d-inline-flex p-ai-center">
                        <i className="fad fa-filter p-mr-2"></i>
                        <span>Filter</span>
                    </div>
                </Divider>
            </div>
        );
    };

    const onRowGroupExpand = (event) => {
        toast.current.show({ severity: 'info', summary: 'Row Group Expanded', detail: 'Value: ' + event.data.type, life: 3000 });
    }

    const onRowGroupCollapse = (event) => {
        toast.current.show({ severity: 'success', summary: 'Row Group Collapsed', detail: 'Value: ' + event.data.type, life: 3000 });
    }

    const onRowReorder = (e) => {

        const reorderedSelectedLayers = e.value.map(
            (item)=>{

                let result = (selectedLayers.filter(
                    (selectedItem)=>{
                        if(selectedItem.id === item.id){
                            return selectedItem
                        }
                    }
                ))
                return result[0];
            }
        )
        let cleanSelection = reorderedSelectedLayers.filter(function(x) {
            return x !== undefined;
        });

        setLayers(e.value);
        setSelectedLayers([...cleanSelection]);

    }

    const titleTemplate = (data,props) =>{
        return (
            <div>
                <span style={{fontSize:"10px"}}>{data[props.field]}</span>
            </div>
        )

    }

    const updateOpacityLayer = (id,opacity) =>{
        let _layers = layers.map(
            (item)=>{
                if(item.id === id){
                    item.reload = true;
                    item.configuration.opacity = opacity
                }
                return item;
            }
        )

        let _selectedLayers = selectedLayers.map(
            (item)=>{
                if(item.id === id){
                    item.reload = true;
                    item.configuration.opacity = opacity
                }
                return item;
            }
        )



        setLayers([..._layers]);
        setSelectedLayers([..._selectedLayers]);
    }

    const sliderTemplate = (data,props) =>{
        return (
            <div>
                <Slider
                    value={data.configuration[props.field]*100}
                    onChange={(e) => updateOpacityLayer(data.id,e.value/100)}
                    step={1}
                    animate={true}
                />
            </div>
        )
    }


    const sendAction = (action,id) =>{
        const actionObject = {
            action:action,
            id:id
        }
        setAction({...actionObject})
    }

    const zoomTemplate = (data) =>{
        return (
            <div>
                <Button
                    icon="fad fa-search-plus"
                    className="p-button-rounded  p-button-icon-only p-button-text"
                    onClick={()=>sendAction("zoom",data.id)}
                />
            </div>
        )
    }

    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;

        for (const [key, value] of Object.entries(e.files)) {
            _totalSize += value.size;
        }
        setTotalSize(_totalSize);
    }

    const onTemplateUpload = (e) => {
        let _totalSize = 0;
        e.files.forEach(file => {
            _totalSize += (file.size || 0);
        });

        setTotalSize(_totalSize);
        toast.current.show({severity: 'info', summary: 'Success', detail: 'File Uploaded'});
    }

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
    }

    const onTemplateClear = () => {
        setTotalSize(0);
    }

    const onHide = () => {
        setDisplayDialog(false)
    }

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize/10000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <ProgressBar value={value} displayValueTemplate={() => `${formatedValue} / 1 MB`}
                             style={{width: '300px', height: '20px', marginLeft: 'auto'}}>
                </ProgressBar>
            </div>
        );
    }

    const emptyTemplate = () => {
        return (
            <div className="p-d-flex p-ai-center p-dir-col">
                <i className="fad fa-image p-mt-3 p-p-5" style={{'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}></i>
                <span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}} className="p-my-5">Drag and Drop File Here</span>
            </div>
        )
    }

    const chooseOptions = {icon: 'fad fa-image', iconOnly: true,
        className: 'custom-choose-btn p-button-primary p-button-raised p-button-text'};
    const uploadOptions = {icon: 'fad fa-upload', iconOnly: true,
        className: 'custom-upload-btn p-button-success p-button-raised p-button-text'};
    const cancelOptions = {icon: 'fad fa-times', iconOnly: true,
        className: 'custom-cancel-btn p-button-danger p-button-raised p-button-text'};

    const itemTemplate = (file, props) => {
        let fileExt = file.name.split('.').pop();

        let fileIcon ="fad fa-file-code fa-2x";
        if(fileExt === "shp"){
            fileIcon = "fad fa-hexagon fa-2x"
        }else if(fileExt === "geojson"){
            fileIcon = "fad fa-brackets-curly fa-2x"
        }else if(fileExt === "kml"){
            fileIcon = "fad fa-map-marked fa-2x"
        }


        return (
            <div className="p-d-flex p-ai-center p-flex-wrap">
                <div className="p-d-flex p-ai-center" style={{width: '40%'}}>
                    <i className={fileIcon}></i>
                    <span className="p-d-flex p-dir-col p-text-left p-ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="p-px-3 p-py-2" />
                <Button type="button" icon="fad fa-times"
                        className="p-button-danger p-button-raised p-button-text p-ml-auto"
                        onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        )
    }

    const renderHeader = () =>{
        return (
            <div className="">
                <i className="fad fa-layer-group"></i><span className="p-ml-2">Layer Panel</span>
            </div>
        )
    }

    const renderFooter = () =>{
        return (
            <>
                <Divider layout="horizontal"/>
                <div className="p-grid p-justify-around p-col">
                    {/*<Button icon="fad fa-home"
                            className="
                            p-button-success
                            p-button-raised p-button-text
                            p-button-icon-only"
                            onClick={()=>sendAction("recenter","N/A")}

                    />*/}

                    <Button icon="fad fa-upload"
                            className="
                            p-button-success
                            p-button-raised p-button-text
                            p-button-icon-only
                            "
                            onClick={()=>setDisplayDialog(true)}
                    />

                    {/*<Button icon="fad fa-upload"
                            className="
                            p-button-success
                            p-button-raised p-button-text
                            p-button-icon-only
                            "
                            onClick={()=>createPanel()}
                    />*/}

                    <Divider layout="vertical"/>

                    {  <Button icon="fad fa-download"
                            className="
                            p-button-raised p-button-text
                            p-button-icon-only
                            "
                            onClick={()=>expandAll()}
                    />
                    /*

                    <Button icon="fad fa-minus"
                            className="
                            p-button-raised p-button-text
                            p-button-icon-only
                            "
                            onClick={()=>collapseAll()}
                    />*/
                    }

                </div>
            </>

        )
    }

    const expandAll = () => {
        let _expandedRows = {}
        layers.forEach(p => _expandedRows[`${p.id}`] = true);
        setExpandedLayers(_expandedRows);
        toast.current.show({ severity: 'success', summary: 'All Rows Expanded', life: 3000 });
    };

    const collapseAll = () => {
        setExpandedLayers(null);
        toast.current.show({ severity: 'success', summary: 'All Rows Collapsed', life: 3000 });
    };

    const createPanel =()=>{
        jsPanel.create({
            position:    "left-top",
            contentSize: {
                width: 'auto',
                height: 'auto'
            },
            content: panel => {
                const div = document.createElement('div');
                div.id = "test";
                panel.content.append(div);
                const node = document.getElementById("test");
                console.log(node)
                ReactDOM.render(<FileUpload ref={fileUploadRef} name="demo[]" url="https://primefaces.org/primereact/showcase/upload.php" multiple accept="image/*" maxFileSize={1000000}
                                            onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                                            headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                                            chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />, node)


            },
            callback: panel => {
                /*panel.content.style.padding = '10px';
                const maxHeight = window.innerHeight - (window.innerHeight * 30) / 100;
                panel.content.style.maxHeight = `${maxHeight}px`;
                panel.content.style.maxWidth = `${window.innerWidth - 20}px`;*/
            },
        });
    }

    const header = renderHeader();
    const footer = renderFooter();

    const treeTableFuncMap = {
        'globalFilter1': setGlobalFilter1
    };

    const getHeader = (globalFilterKey) => {
        return (
            <div className="p-text-right">
                <div className="p-input-icon-left">
                    <i className="pi pi-search"></i>
                    <InputText type="search" onInput={(e) => treeTableFuncMap[`${globalFilterKey}`](e.target.value)} placeholder="Global Search" size="50" />
                </div>
            </div>
        );
    }

    let header1 = getHeader('globalFilter1');

    const leftContents = (
        <React.Fragment>
            <Button type="button" icon="pi pi-search" label={selectedProduct ? selectedProduct.name : 'Select a Product'} onClick={(e) => op.current.toggle(e)} aria-haspopup aria-controls="overlay_panel" className="select-product-button" />
            <OverlayPanel ref={op} showCloseIcon id="overlay_panel"  className="overlaypanel-demo">
                <DataTable value={products} selectionMode="single"
                           style={{width:"600px"}}
                           paginator rows={5}
                           selection={selectedProduct} onSelectionChange={onProductSelect}>
                    <Column field="name" style={{width:"100px"}} header="Name" sortable />
                    <Column header="description" style={{width:"250px"}} header="Description" body={imageBody} />
                    <Column field="link" style={{width:"250px"}} header="Location" sortable body={priceBody} />
                </DataTable>
            </OverlayPanel>
        </React.Fragment>
    );

    const rightContents = (
        <React.Fragment>
            <Button type="button" icon="pi pi-search" label={selectedProduct ? selectedProduct.name : 'Select a Product'} onClick={(e) => op.current.toggle(e)} aria-haspopup aria-controls="overlay_panel" className="select-product-button" />
            <OverlayPanel ref={op} showCloseIcon id="overlay_panel"  className="overlaypanel-demo">
                <DataTable value={products} selectionMode="single"
                           style={{width:"600px"}}
                           paginator rows={5}
                           selection={selectedProduct} onSelectionChange={onProductSelect}>
                    <Column field="name" style={{width:"100px"}} header="Name" sortable />
                    <Column header="description" style={{width:"250px"}} header="Description" body={imageBody} />
                    <Column field="link" style={{width:"250px"}} header="Location" sortable body={priceBody} />
                </DataTable>
            </OverlayPanel>
        </React.Fragment>
    );

    const onChange = (event) => {
        setSource(event.source);
        setTarget(event.target);
    }


    const groupedCities = [
        {
            label: 'Crop', code: 'DE',
            items: [
                { label: 'Berlin', value: 'Berlin' },
                { label: 'Frankfurt', value: 'Frankfurt' },
                { label: 'Hamburg', value: 'Hamburg' },
                { label: 'Munich', value: 'Munich' }
            ]
        },
        {
            label: 'Animal', code: 'US',
            items: [
                { label: 'Chicago', value: 'Chicago' },
                { label: 'Los Angeles', value: 'Los Angeles' },
                { label: 'New York', value: 'New York' },
                { label: 'San Francisco', value: 'San Francisco' }
            ]
        },
        {
            label: 'Japan', code: 'JP',
            items: [
                { label: 'Kyoto', value: 'Kyoto' },
                { label: 'Osaka', value: 'Osaka' },
                { label: 'Tokyo', value: 'Tokyo' },
                { label: 'Yokohama', value: 'Yokohama' }
            ]
        }
    ];

    const groupedItemTemplate = (option) => {
        return (
            <div className="flex align-items-center country-item">

                <div><i className="pi pi-folder-open"></i> {option.label}</div>
            </div>
        );
    }

    const countryTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.name}</div>
            </div>
        );
    }

    const crops = [
        { name: 'Maize', code: 'MZ' },
        { name: 'Wheat', code: 'WH' },
        { name: 'Barley', code: 'BR' }
    ];

    const variables = [
        { name: 'Harvested Area', code: 'HA' },
        { name: 'Physical Area', code: 'PA' },
        { name: 'Production Area', code: 'RA' }
    ];

    const panelFooterTemplate = () => {
        const selectedItems = selectedVariable;
        const length = selectedItems ? selectedItems.length : 0;
        return (
            <div className="p-py-2 p-px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    }

    return(
        <div className="">
            <Toast ref={toast}></Toast>
            <h5>Grouped</h5>
            <MultiSelect value={selectedGroupedCities}
                         options={groupedCities}
                         onChange={(e) => setSelectedGroupedCities(e.value)}
                         optionLabel="label" optionGroupLabel="label" optionGroupChildren="items"
                         optionGroupTemplate={groupedItemTemplate} placeholder="Select Cities" />
            <div className=" dashboard-grid">
                <BlockUI blocked={false}>
                    <Panel header="Production" toggleable>
                        <div className="p-grid p-justify-between">
                            <div className="p-col-3">
                                <div className="multiselect-demo">
                                    <MultiSelect value={selectedCrops}
                                                 options={crops}
                                                 onChange={(e) => setSelectedCrops(e.value)} optionLabel="name"
                                                 placeholder="Select Crop(s)" filter className="multiselect-custom"
                                                 itemTemplate={countryTemplate}
                                                 display="chip"
                                    />
                                </div>
                            </div>
                            <div className="p-col-3">
                                <div className="multiselect-demo">
                                    <MultiSelect value={selectedVariable}
                                                 options={variables}
                                                 onChange={(e) => setSelectedVariable(e.value)} optionLabel="name"
                                                 placeholder="Select Variable(s)" filter className="multiselect-custom"
                                                 itemTemplate={countryTemplate}
                                                 display="chip"
                                                 maxSelectedLabels={2}
                                                 panelFooterTemplate={panelFooterTemplate}
                                    />
                                </div>
                            </div>
                            <div className="p-col-2 ">
                                <Button
                                    icon="pi pi-search"
                                    label="Search Layers"
                                    className="p-button-raised" />
                            </div>
                        </div>
                        <Divider align="center">
                            <div className="p-d-inline-flex p-ai-center">
                                <i className="pi pi-folder-open p-mr-2"></i>
                                <b>Results</b>
                            </div>
                        </Divider>
                        <div>
                            <PickList source={source} target={target}
                                      sourceHeader="Available" targetHeader="Selected"
                                      sourceStyle={{ height: '342px' }} targetStyle={{ height: '342px' }}
                                      onChange={onChange}>
                            </PickList>
                        </div>
                    </Panel>
                </BlockUI>
                <div className="p-col-12" hidden={hideVariables}>
                    <Panel header="Select Dataset" toggleable>
                            <TreeTable value={nodes}
                                       selectionMode="checkbox"
                                       selectionKeys={selectedNodeKeys3}
                                       onSelectionChange={e => setSelectedNodeKeys3(e.value)}
                                       globalFilter={globalFilter1}
                                       header={header1}
                                       paginator rows={5}
                                       resizableColumns columnResizeMode="fit" showGridlines
                            >
                                <Column field="name"  header="Name" expander sortable></Column>
                                <Column field="about" header="Description"></Column>
                                <Column field="location" header="Location"></Column>
                            </TreeTable>
                    </Panel>
                </div>

                <div className="p-col" hidden={hideMap}>
                    <div className="card">
                        <Map layers={selectedLayers} action={action}/>
                    </div>
                </div>

                <div className="p-col-4" hidden={hideLayers}>
                    <div className="card">
                        <div className="card-body">
                            <DataTable
                                footer={footer}
                                header={header}
                                value={layers}
                                dataKey="id"
                                className="p-datatable-customers p-datatable-sm"
                                autoLayout={true}
                                stripedRows
                                expandedRows={expandedLayers}
                                onRowToggle={(e) => setExpandedLayers(e.data)}
                                rowExpansionTemplate={rowExpansionTemplate}
                                onRowExpand={onRowGroupExpand}
                                onRowCollapse={onRowGroupCollapse}
                                emptyMessage = "No Available Layers"
                                selection={selectedLayers}
                                onSelectionChange={e => setSelectedLayers(e.value)}
                                onRowReorder={onRowReorder}
                                selectionMode="checkbox"
                            >

                                <Column expander headerStyle={{ width: '2rem' }} />
                                <Column selectionMode="multiple" style={{width:'2em'}}/>
                                <Column
                                    key="name"
                                    field="name"
                                    header="Layer"
                                    body={(data,props) => titleTemplate(data,props)}
                                />
                                <Column
                                    key="opacity"
                                    field="opacity"
                                    header="Opacity"
                                    body={(data,props) => sliderTemplate(data,props)}
                                />
                                <Column  style={{width: '2em'}}
                                         body={(data,props) => zoomTemplate(data)}
                                />
                                <Column rowReorder rowReorderIcon="fad fa-arrows" style={{width: '2em'}} />

                            </DataTable>
                        </div>
                    </div>
                </div>
                <Dialog header="Upload your Polygon"
                        modal style={{ width: '50vw' }}
                        visible={displayDialog}
                        onHide={() => onHide()}
                        draggable={false} resizable={false}>

                    <FileUpload ref={fileUploadRef} name="demo[]" url="https://primefaces.org/primereact/showcase/upload.php" multiple accept="image/*" maxFileSize={1000000}
                                onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                                headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                                chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />

                </Dialog>

            </div>

            <div>
                <p>Attributions:</p>
                <p>The <a href="http://gadm.org/" target="_blank">
                    Database of Global Administrative Areas (GADM)</a> has been used as the source of the boundaries and names shown.</p>
                <p>The map is Powered by Esri</p>
                <p>Map Disclaimer: The boundaries and names shown and the designations used on this map do not imply official endorsement or acceptance by CGIAR Platform for Big Data in Agriculture. Dotted lines represent approximately the Line of Control in Jammu and Kashmir agreed upon by India and Pakistan. The final status of Jammu and Kashmir has not yet been agreed by the parties. Final boundary between the Republic of Sudan and Republic of South Sudan has not yet been determined.</p>
            </div>
        </div>
    )
}
