import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom'
import AppWrapper from './AppWrapper';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import Gleap from 'gleap';

// Please make sure to call this method only once!
Gleap.initialize("wVlJOkahMqKLZ2lx7OCq4Cot318rAAh6");

Sentry.init({
    dsn: "https://1759b1339a294f489ac742d6fb24ead4@o924383.ingest.sentry.io/4504690023923712",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

ReactDOM.render(
	<HashRouter>
		<AppWrapper></AppWrapper>
	</HashRouter>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
