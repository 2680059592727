import React, {useEffect, useRef, useState } from 'react';
import * as ReactDOM from "react-dom";
import {Map} from "./leaflet/Map";
import TerracottaService from "../../service/TerracottaService";
import {TabPanel, TabView} from "primereact/tabview";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {Boxplot} from "./Boxplot";
import {Slider} from "primereact/slider";
import {Dropdown} from "primereact/dropdown";
import {Panel} from "primereact/panel";
import { FileUpload } from 'primereact/fileupload';
import { Tag } from 'primereact/tag';
import { ProgressBar } from 'primereact/progressbar';
import {ColorPicker} from 'primereact/colorpicker';
import {Toast} from "primereact/toast";
import {PolygonActionComponent} from "./PolygonActionComponent";

export const GlowGlobe = (props) => {

    const [soilMetadata, setSoilMetadata] = useState(null);
    const [cropMetadata, setCropMetadata] = useState(null);
    const [climateMetadata, setClimateMetadata] = useState(null);
    const [selectedSoils, setSelectedSoils] = useState(null);
    const [layers, setLayers] = useState(null);
    const [selectedLayers, setSelectedLayers] = useState(null);
    const [customLayers, setCustomLayers] = useState(null);
    const [checkLayers, setCheckLayers] = useState(null);
    const [selectedCustomLayers, setCustomSelectedLayers] = useState(null);
    const [basemaps, setBasemaps] = useState(null);
    const [selectedBasemap, setSelectedBasemap] = useState(null);
    const [mapdata, setMapdata] = useState({layers:null,basemap:null,custom:null});
    const [expandedLayers, setExpandedLayers] = useState([]);
    const [loadingMap, setLoadingMap] = useState(false);
    const [blockMap, setBlockMap] = useState(false);
    const [layerAction, setLayerAction] = useState(null);

    const [selectedColormap, setSelectedColormap] = useState(null);
    const [selectedColor, setSelectedColor] = useState([]);
    const [datasetSelector, setDatasetSelector] = useState(false);
    const [layerPanel, setLayerPanel] = useState(false);
    const [uploadCustomPolygon, setUploadCustomPolygon] = useState(false);
    const [boxplotViewer, setBoxplotViewer] = useState(false);
    const [statistics, setStatistics] = useState(null);

    const [totalSize, setTotalSize] = useState(0);
    const op = useRef(null);
    const toast = useRef(null);
    const fileUploadRef = useRef(null);


    const colormapOptions = [
        { name: 'accent', code: 'accent' },
        { name: 'afmhot', code: 'afmhot' },
        { name: 'autumn', code: 'autumn' },
        { name: 'binary', code: 'binary' },
        { name: 'blues', code: 'blues' },
        { name: 'bone', code: 'bone' },
        { name: 'brbg', code: 'brbg' },
        { name: 'brg', code: 'brg' },
        { name: 'bugn', code: 'bugn' },
        { name: 'bupu', code: 'bupu' },
        { name: 'bwr', code: 'bwr' },
        { name: 'cividis', code: 'cividis' },
        { name: 'cloud', code: 'cloud' },
        { name: 'cmrmap', code: 'cmrmap' },
        { name: 'cool', code: 'cool' },
        { name: 'coolwarm', code: 'coolwarm' },
        { name: 'copper', code: 'copper' },
        { name: 'cubehelix', code: 'cubehelix' },
        { name: 'dark2', code: 'dark2' },
        { name: 'flag', code: 'flag' },
        { name: 'gist_earth', code: 'gist_earth' },
        { name: 'gist_gray', code: 'gist_gray' },
        { name: 'gist_heat', code: 'gist_heat' },
        { name: 'gist_ncar', code: 'gist_ncar' },
        { name: 'gist_rainbow', code: 'gist_rainbow' },
        { name: 'gist_stern', code: 'gist_stern' },
        { name: 'gist_yarg', code: 'gist_yarg' },
        { name: 'gnbu', code: 'gnbu' },
        { name: 'gnuplot', code: 'gnuplot' },
        { name: 'gnuplot2', code: 'gnuplot2' },
        { name: 'gray', code: 'gray' },
        { name: 'greens', code: 'greens' },
        { name: 'greys', code: 'greys' },
        { name: 'hot', code: 'hot' },
        { name: 'hsv', code: 'hsv' },
        { name: 'inferno', code: 'inferno' },
        { name: 'jet', code: 'jet' },
        { name: 'magma', code: 'magma' },
        { name: 'nipy_spectral', code: 'nipy_spectral' },
        { name: 'ocean', code: 'ocean' },
        { name: 'oranges', code: 'oranges' },
        { name: 'orrd', code: 'orrd' },
        { name: 'paired', code: 'paired' },
        { name: 'pastel1', code: 'pastel1' },
        { name: 'pastel2', code: 'pastel2' },
        { name: 'pink', code: 'pink' },
        { name: 'piyg', code: 'piyg' },
        { name: 'plasma', code: 'plasma' },
        { name: 'prgn', code: 'prgn' },
        { name: 'prism', code: 'prism' },
        { name: 'pubu', code: 'pubu' },
        { name: 'pubugn', code: 'pubugn' },
        { name: 'puor', code: 'puor' },
        { name: 'purd', code: 'purd' },
        { name: 'purples', code: 'purples' },
        { name: 'rainbow', code: 'rainbow' },
        { name: 'rdbu', code: 'rdbu' },
        { name: 'rdgy', code: 'rdgy' },
        { name: 'rdpu', code: 'rdpu' },
        { name: 'rdylbu', code: 'rdylbu' },
        { name: 'rdylgn', code: 'rdylgn' },
        { name: 'reds', code: 'reds' },
        { name: 'set1', code: 'set1' },
        { name: 'set2', code: 'set2' },
        { name: 'set3', code: 'set3' },
        { name: 'spectral', code: 'spectral' },
        { name: 'spring', code: 'spring' },
        { name: 'summer', code: 'summer' },
        { name: 'tab10', code: 'tab10' },
        { name: 'tab20', code: 'tab20' },
        { name: 'tab20b', code: 'tab20b' },
        { name: 'tab20c', code: 'tab20c' },
        { name: 'terrain', code: 'terrain' },
        { name: 'twilight', code: 'twilight' },
        { name: 'twilight_shifted', code: 'twilight_shifted' },
        { name: 'viridis', code: 'viridis' },
        { name: 'winter', code: 'winter' },
        { name: 'wistia', code: 'wistia' },
        { name: 'ylgn', code: 'ylgn' },
        { name: 'ylgnbu', code: 'ylgnbu' },
        { name: 'ylorbr', code: 'ylorbr' },
        { name: 'ylorrd', code: 'ylorrd' }
    ];

    useEffect(() => {

        // setLoadingMap(true);
        const terracottaService = new TerracottaService();
        terracottaService.getSpatialDatasets().then(
            (result)=>{
                if(result.status === 200){
                    toast.current.show({severity:'success', summary: 'Spatial Datasets',
                        detail:'All Datasets are ready for use', life: 3000});
                    Promise.all(result.data.soil).then((metadata) => {
                        setSoilMetadata(metadata);
                    });
                    Promise.all(result.data.crop).then((metadata) => {
                        setCropMetadata(metadata);
                    });
                    Promise.all(result.data.climate).then((metadata) => {
                        setClimateMetadata(metadata);
                    });
                }else if(result.status === 500){
                    toast.current.show({severity:'error', summary: 'Spatial Datasets',
                        detail:'A unexpected error has occurred', life: 3000});
                }
                setBlockMap(false);
            }).catch(
                (error)=>{
                    toast.current.show({severity:'error', summary: 'Spatial Datasets',
                        detail:'A unexpected error has occurred', life: 3000});
                })

        terracottaService.getBasemaps().then(
            (result)=>{
                let defaultBasemap = result.find(
                    (basemap) => {
                        if(basemap.default === true){
                            return basemap;
                        }
                    }
                )
                let tempMapdata = mapdata;
                tempMapdata.basemap = defaultBasemap;
                setMapdata({...tempMapdata});
                setSelectedBasemap(defaultBasemap);
                setBasemaps(result);
            }
        ).catch(
            (error)=>{
                toast.current.show({severity:'error', summary: 'Spatial Datasets',
                    detail:'A unexpected error has occurred', life: 3000});
            })
    }, []);

    useEffect(
        ()=>{
            if(checkLayers){
                if(customLayers){
                    let currentCustomData = customLayers;
                    let _currentCustomData = [...currentCustomData,...checkLayers];
                    setCustomLayers([..._currentCustomData]);
                }else{
                    setCustomLayers([...checkLayers]);
                }

                if(selectedCustomLayers){
                    let selectedCustomData = selectedCustomLayers;
                    let _selectedCustomData = [...selectedCustomData,...checkLayers];
                    setCustomSelectedLayers([..._selectedCustomData]);
                }else{
                    setCustomSelectedLayers([...checkLayers]);
                }

                let tempMapdata = mapdata;
                if(tempMapdata.custom){
                    let currentCustomData = tempMapdata.custom;
                    tempMapdata.custom = [...currentCustomData, ...checkLayers];
                    setMapdata({...tempMapdata});
                }else{
                    tempMapdata.custom = checkLayers;
                    setMapdata({...tempMapdata});
                }
            }
        }, [checkLayers]);



    const action = (value) =>{
        if( value.action === "enableDatasetSelector"){
            setDatasetSelector(true);
        }else if(value.action === "download"){
            // element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
            let element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' +
                encodeURIComponent(JSON.stringify(value.data.layer)));
            element.setAttribute('download', value.data.title+".geojson");
            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
            toast.current.show({severity:'success', summary: 'Download '+value.data.title+' Data',
                detail:'The data are travelling to your machine', life: 3000});
        }else if(value.action === "copy"){
            navigator.clipboard.writeText(JSON.stringify(value.data.layer)).then(
                ()=>{
                    toast.current.show({severity:'success', summary: 'Copy',
                        detail:value.data.label +' has been copied', life: 3000});
                }
            );
        }else if(value.action === "enableLayerPanel"){
            setLayerPanel(true);
        }else if(value.action === "enableUploadCustomPolygon"){
            setUploadCustomPolygon(true);
        }else if(value.action === "administrationLevel"){
            setBlockMap(true);
            const terracottaService = new TerracottaService();
            terracottaService.isSea(value.request).then(
                (result)=>{
                    if(!result){
                        terracottaService.getGADMPolygon(value.request).then(
                            (gadmData)=>{
                                if(gadmData.length < 1){
                                    toast.current.show({severity:'warn', summary: 'GADM Data',
                                        detail:'No Polygon Found ...', life: 3000});
                                }else if(gadmData.status === 500){
                                    toast.current.show({severity:'error', summary: 'GADM Data',
                                        detail:'GADM Service is unavailable', life: 3000});
                                }else{
                                    toast.current.show({severity:'success', summary: 'GADM Data',
                                        detail:'Polygon Loaded', life: 3000});
                                }
                                setCheckLayers([...gadmData]);
                                setBlockMap(false);
                            }
                        )
                    }else if(result){
                        setBlockMap(false);
                        toast.current.show({severity:'warn', summary: 'GADM Data',
                            detail:'Is your point in sea?', life: 3000});
                    }
                }
            );

        }
    }


    const actionPolygon = (value) =>{
        if(value.action === 'remove'){
            let tempMapdata = mapdata;
            let filteredCustomPolygons = tempMapdata.custom.filter(
                (item) => {
                    if(item.id !== value.id){
                        return item;
                    }
                }
            )

            let filteredCustomLayers = customLayers.filter(
                (item) => {
                    if(item.id !== value.id){
                        return item;
                    }
                }
            )

            tempMapdata.custom = filteredCustomPolygons;
            setMapdata({...tempMapdata});
            setCustomSelectedLayers(filteredCustomPolygons);
            setCustomLayers(filteredCustomLayers);
        }else if(value.action === 'add'){
            let geojson = {};
            geojson.color = "#c800ff"
            geojson.id = Math.floor(Math.random() * 100);
            geojson.layer = value.geojson.layer;
            geojson.opacity = 0.5
            let title = value.geojson.sourcePolygon.title+" ("+value.geojson.type+")";
            geojson.title = title

            if(customLayers){
                let currentCustomData = customLayers;
                let _currentCustomData = [...currentCustomData,...[geojson]];
                setCustomLayers([..._currentCustomData]);
            }else{
                setCustomLayers([geojson]);
            }

            if(selectedCustomLayers){
                let selectedCustomData = selectedCustomLayers;
                let _selectedCustomData = [...selectedCustomData,...[geojson]];
                setCustomSelectedLayers([..._selectedCustomData]);
            }else{
                setCustomSelectedLayers([geojson]);
            }


            let tempMapdata = mapdata;
            if(tempMapdata.custom){
                let currentCustomData = tempMapdata.custom;
                tempMapdata.custom = [...currentCustomData, ...[geojson]];
                setMapdata({...tempMapdata});
            }else{
                tempMapdata.custom = [geojson];
                setMapdata({...tempMapdata});
            }


            // setCheckLayers([...value.geojson.layer]);
            // setBlockMap(false);
        }
        setLayerAction({...value});
    }




    const onHide = () => {
        setDatasetSelector(false);
    }

    const onHideBoxplot = () => {
        setBoxplotViewer(false);
    }

    const onHideLayerPanel = () => {
        setLayerPanel(false);
    }

    const onHideUploadCustomPolygon = () => {
        setUploadCustomPolygon(false);
    }

    const showBoxPlot = (stats) => {
        setBoxplotViewer(false);
        if(statistics){
            setStatistics({...stats});
        }else{
            setStatistics(stats);
        }

        setBoxplotViewer(true);
    }

    const actionBodyTemplate = (row) => {
        let stats = {
            mean:row.mean,
            percentiles: row.percentiles,
            stdev: row.stdev,
            range: row.range,
            valid_percentage: row.valid_percentage
        }
        return <Button
            type="button"
            icon="fad fa-chart-line"
            tooltip={"COMING SOON: Check the basic statistics of the dataset"}
            tooltipOptions={{ className: 'blue-tooltip', position: 'top' }}
                       onClick={()=>showBoxPlot(stats)}
            disabled={true}>

        </Button>;
    }

    const customPolygonActionBodyTemplate = (rowData) => {

        let data = {}
        data.rowData = rowData;
        data.layers = layers;

        return (
            <>
                <PolygonActionComponent data={data} action={actionPolygon}/>
            </>
        );
    }

    const removeLayer = (e,rowData) =>{
        let tempMapdata = mapdata;

        let filteredMapLayers = tempMapdata.layers.filter(
            (item) => {
                if(item.id !== rowData.id){
                    return item;
                }
            }
        )

        let filteredPanelLayers = layers.filter(
            (item) => {
                if(item.id !== rowData.id){
                    return item;
                }
            }
        )

        tempMapdata.layers = filteredMapLayers;
        setMapdata({...tempMapdata});
        setSelectedLayers(filteredMapLayers);
        setLayers(filteredPanelLayers);

    }

    const downloadLayer = (e,rowData) =>{
        setBlockMap(true);
        let type = '';
        if(rowData.s3_link.includes('climate')){
            type = 'climate';
        }else if(rowData.s3_link.includes('crops')){
            type = 'crops';
        }else if(rowData.s3_link.includes('soil')){
            type = 'soil';
        }

        const terracottaService = new TerracottaService();
        terracottaService.getPresignedTiffURL(rowData.id,type).then(
            (response)=>{
                if(response.status === 500){
                    toast.current.show({severity:'error', summary: 'Download Layer: '+rowData.title,
                        detail:'A unexpected error has occurred', life: 3000});
                    setLoadingMap(false);
                }else{
                    const url = response.data.download_link;
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        rowData.id+'.tif',
                    );
                    // Append to html link element page
                    document.body.appendChild(link);
                    // Start download
                    link.click();
                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    toast.current.show({severity:'success', summary: 'Download Layer: '+rowData.title, detail:'The data are travelling to your machine', life: 3000});
                    setLoadingMap(false);
                }
                setBlockMap(false);
                setLoadingMap(false);
            }
        )
    }

    const layerActionBodyTemplate = (rowData) => {
        return (
            <>
                <Button type="button"
                        icon="fad fa-download"
                        tooltip={"Download "+rowData.title}
                        tooltipOptions={{ className: 'blue-tooltip', position: 'top' }}
                        className="p-mr-2"
                        onClick={(e) => downloadLayer(e,rowData)}
                        />
                <Button type="button"
                        icon="fad fa-times"
                        tooltip={"Remove "+rowData.title}
                        tooltipOptions={{ className: 'red-tooltip', position: 'top' }}
                        className="p-button-danger"
                        onClick={(e) => removeLayer(e,rowData)}
                />
            </>
        );
    }

    const headerSoil = () => {
        return (
            <div className="flex justify-content-between">
                <Button
                    type="button"
                    icon="fad fa-book"
                    tooltip={"Open the API documentation"}
                    tooltipOptions={{ className: 'blue-tooltip', position: 'top' }}
                    label="API Documentation"
                    onClick={()=>window.open("https://soil.terracotta.qvantum.scio.services/apidoc",
                        '_blank')
                    }
                >

                </Button>

            </div>
        )
    }

    const headerCrop = () => {
        return (
            <div className="flex justify-content-between">
                <Button
                    type="button"
                    icon="fad fa-book"
                    tooltip={"Open the API documentation"}
                    tooltipOptions={{ className: 'blue-tooltip', position: 'top' }}
                    label="API Documentation"
                    onClick={()=>window.open("https://crops.terracotta.qvantum.scio.services/apidoc",
                        '_blank')
                    }
                >

                </Button>

            </div>
        )
    }

    const headerClimate = () => {
        return (
            <div className="flex justify-content-between">
                <Button
                    type="button"
                    icon="fad fa-book"
                    tooltip={"Open the API documentation"}
                    tooltipOptions={{ className: 'blue-tooltip', position: 'top' }}
                    label="API Documentation"
                    onClick={()=>window.open("https://climate.terracotta.qvantum.scio.services/apidoc",
                        '_blank')
                    }
                >

                </Button>

            </div>
        )
    }

    const loadDatasets = () =>{
        let selectionColormapStructure = {};
        selectedSoils.forEach(
            (item)=>{
                selectionColormapStructure[item.id] = { name: 'greys', code: 'greys' };
            }
        )
        setSelectedColormap(selectionColormapStructure);
        let tempMapdata = mapdata;
        tempMapdata.layers = selectedSoils;
        setLayers(selectedSoils);
        setSelectedLayers(selectedSoils);
        setMapdata({...tempMapdata});
        setDatasetSelector(false);
    }

    const loadBasemap = (selection) =>{
        let tempMapdata = mapdata;
        tempMapdata.basemap = selection;
        setMapdata({...tempMapdata});
        setSelectedBasemap(selection);
    }

    const loadLayers = (selection) =>{
        let tempMapdata = mapdata;
        tempMapdata.layers = selection;
        setMapdata({...tempMapdata});
        setSelectedLayers(selection);
    }

    const loadCustomLayers = (selection) =>{
        let tempMapdata = mapdata;
        tempMapdata.custom = selection;
        setMapdata({...tempMapdata});
        setCustomSelectedLayers(selection);
    }

    const renderDatasetSelectorFooter = () => {
        return (
            <div className="flex justify-content-between">
                <Button
                    type="button"
                    tooltip={"Load the selected layers to the map"}
                    tooltipOptions={{ className: 'blue-tooltip', position: 'top' }}
                    icon="fad fa-truck-loading"
                    label="Load Selections to Map"
                    onClick={()=>loadDatasets()
                    }
                >

                </Button>

            </div>
        )
    }

    const updateOpacityLayer = (id,opacity) =>{
        let tempLayers = layers.map(
            (item)=>{
                if(item.id === id){
                    item.opacity = opacity
                }
                return item;
            }
        )

        let tempSelectedLayers = selectedLayers.map(
            (item)=>{
                if(item.id === id){
                    item.opacity = opacity
                }
                return item;
            }
        )

        let tempMapdata = mapdata;
        tempMapdata.layers = tempLayers;
        setMapdata({...tempMapdata});
        setLayers([...tempLayers]);
        setSelectedLayers([...tempSelectedLayers]);
    }

    const opacitySlider = (data,props) =>{
        return (
            <div>
                <Slider
                    value={data[props.field]*100}
                    onChange={(e) => updateOpacityLayer(data.id,e.value/100)}
                    step={1}
                    animate={true}
                />
            </div>
        )
    }

    const opacityCustomSlider = (data,props) =>{
        return (
            <div>
                <Slider
                    value={data[props.field]*100}
                    onChange={(e) => updateCustomOpacityLayer(data.id,e.value/100)}
                    step={1}
                    animate={true}
                />
            </div>
        )
    }

    const updateCustomOpacityLayer = (id,opacity) =>{
        let tempLayers = customLayers.map(
            (item)=>{
                if(item.id === id){
                    item.opacity = opacity
                }
                return item;
            }
        )

        let tempSelectedLayers = selectedCustomLayers.map(
            (item)=>{
                if(item.id === id){
                    item.opacity = opacity
                }
                return item;
            }
        )

        let tempMapdata = mapdata;
        tempMapdata.custom = tempLayers;
        setMapdata({...tempMapdata});
        setCustomLayers([...tempLayers]);
        setCustomSelectedLayers([...tempSelectedLayers]);
    }

    const onColormapChange = (e,id) => {
        let tempSelectedColormap = selectedColormap;
        tempSelectedColormap[id] = e.value;
        setSelectedColormap({...tempSelectedColormap});
        let updated = selectedSoils.map(
            (item)=>{
                if(item.id === id){
                    let tempItem = item;
                    let tempTileUrl = tempItem.tileUrl;
                    let indexOfSeparator = tempTileUrl.indexOf('?');
                    let updatedTileUrl = tempTileUrl.substring(0,indexOfSeparator)+'?colormap='+e.value.code;
                    tempItem.additionalAction = 'update';
                    tempItem.tileUrl = updatedTileUrl;
                    return tempItem;
                }else{
                    return item;
                }
            }
        )
        setSelectedSoils([...updated]);
        let updatedLayers = selectedLayers.map(
            (item)=>{
                if(item.id === id){
                    let tempItem = item;
                    let tempTileUrl = tempItem.tileUrl;
                    let indexOfSeparator = tempTileUrl.indexOf('?');
                    let updatedTileUrl = tempTileUrl.substring(0,indexOfSeparator)+'?colormap='+e.value.code;
                    tempItem.tileUrl = updatedTileUrl;
                    return tempItem;
                }else{
                    return item;
                }
            }
        )

        loadLayers(updatedLayers);
    }

    const onColorChange = (e,id) =>{
        let tempSelectedColor = selectedColor;
        tempSelectedColor[id] = e.value;
        setSelectedColor({...tempSelectedColor});

        let tempCustomLayers = customLayers.map(
            (item) => {
                if(item.id === id){
                    item.color = '#'+e.value;
                }
                return item;
            }
        )

        let tempSelectedCustomLayers = selectedCustomLayers.map(
            (item) => {
                if(item.id === id){
                    item.color = '#'+e.value;
                }
                return item;
            }
        )

        let tempMapdata = mapdata;
        tempMapdata.custom = tempSelectedCustomLayers;
        setMapdata({...tempMapdata});
        setCustomLayers([...tempCustomLayers]);
        setCustomSelectedLayers([...tempSelectedCustomLayers]);
    }

    const colormapOptionTemplate = (option) => {
        let filename = 'cmap-'+option.code+'-bar.webp';
        let path = "./colormaps/"+filename;
        return (
            <div className="country-item">
                <img alt={option.name} src={path}
                     />
                <div>{option.name}</div>
            </div>
        );
    }

    const rowExpansionTemplate = (rowData) => {
        const id = rowData.id;

        if(selectedColormap){
            let filename = 'cmap-'+selectedColormap[id].code+'-bar.webp';
            let path = "./colormaps/"+filename;
            return (
                <div className="card p-grid p-shadow-24">
                    <div className="p-col-2 p-mr-6">
                        <Dropdown
                            value={selectedColormap[id]}
                            options={colormapOptions}
                            onChange={(e)=>onColormapChange(e,id)}
                            optionLabel="name" filter filterBy="name"
                            placeholder="Select a Colormap"
                            itemTemplate={colormapOptionTemplate}
                        />
                    </div>

                    <div className="p-col">
                        <div>
                            <img alt={selectedColormap[id].name} src={path} width="100%"
                                 height="25px"
                            />
                        </div>
                        <div className="legend-labels">
                            <span>{rowData.legend.min}</span>
                            <span>{rowData.legend.max}</span>
                        </div>
                    </div>

                </div>
            );
        }
    };

    const imageBodyTemplate = (rowData) => {
        let filename = rowData.thumbnail;
        let path = "./basemaps/"+filename;
        return (<img src={path} height = "100px"/>);
    }

    const onRowReorder = (e) => {
        let tempMapdata = mapdata;
        tempMapdata.layers = e.value;
        setMapdata({...tempMapdata});
        setLayers([...e.value]);
    }

    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;

        if(Array.isArray(e.files)){
            e.files.forEach(file => {
                _totalSize += file.size;
            });
        }else{
            _totalSize += e.files[0].size;
        }
        setTotalSize(_totalSize);
    }

    const onTemplateUpload = (e) => {
        let _totalSize = 0;
        e.files.forEach(file => {
            _totalSize += (file.size || 0);
        });

        if(Array.isArray(e.files)){
            e.files.forEach(file => {
                _totalSize += (file.size || 0);
            });
        }else{
            _totalSize += (e.files[0].size || 0 );
        }
        setTotalSize(_totalSize);
        toast.current.show({severity: 'info', summary: 'Success', detail: 'File Uploaded'});
    }

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
    }

    const onTemplateClear = () => {
        setTotalSize(0);
    }

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize/10000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';
        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <ProgressBar value={value} displayValueTemplate={() => `${formatedValue} / 1 MB`}
                             style={{width: '300px', height: '20px', marginLeft: 'auto'}}>
                </ProgressBar>
            </div>
        );
    }

    const itemTemplate = (file, props) => {

        return (
            <div className="p-d-flex p-ai-center p-flex-wrap">
                <div className="p-d-flex p-ai-center" style={{width: '40%'}}>
                    <span className="p-d-flex p-dir-col p-text-left p-ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="p-px-3 p-py-2" />
                <Button type="button"
                        icon="fad fa-times"
                        tooltip={"Cancel "+file.name+" Upload"}
                        tooltipOptions={{ className: 'red-tooltip', position: 'top' }}
                        className="p-button-outlined p-button-rounded p-button-danger p-ml-auto"
                        onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        )
    }

    const emptyTemplate = () => {
        return (
            <div className="p-d-flex p-ai-center p-dir-col">
                <i className="fad fa-draw-polygon p-mt-3 p-p-5"
                   style={{'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}></i>
                <span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}}
                      className="p-my-5">Drag and Drop Your Geojson Here</span>
            </div>
        )
    }

    const selectColor = (rowData) =>{
        let color = '';
        if(selectedColor[rowData.id] === undefined){
            color = rowData.color;
        }else{
            color = selectedColor[rowData.id];
        }

        return (
            <ColorPicker value={color}
                     onChange={(e) => onColorChange(e,rowData.id)}>
            </ColorPicker>
        );
    }

    const chooseOptions = {
        icon: 'fad fa-draw-polygon',
        iconOnly: true,
        className: 'custom-choose-btn p-button-rounded p-button-outlined'
    };
    const uploadOptions = {icon: 'fad fa-truck-loading', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined'};
    const cancelOptions = {icon: 'fad fa-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'};

    const customPolygonUploader = (event) => {

        const get_file_content = (file) => {
            return new Promise((acc, err) => {
                const reader = new FileReader();
                reader.onload = (event) => { acc(event.target.result) };
                reader.onerror = (err)  => { err(err) };
                reader.readAsText(file);
            });
        }

        let contents = event.files.map(
            (item) =>{
                const itemContent = get_file_content(item).then(
                    (response)=>{
                        let title = item.name;
                        let polygon ={
                            title,
                            opacity:0.5,
                            id: Math.floor(Math.random() * 100),
                            layer: JSON.parse(response),
                            color: "#ff0000",
                        }
                        return polygon;
                    }
                );
                return itemContent;
            }
        )

        Promise.all(contents).then((customData)=>{
            if(customLayers){
                let currentCustomData = customLayers;
                let _currentCustomData = [...currentCustomData,...customData];
                setCustomLayers([..._currentCustomData]);
            }else{
                setCustomLayers(customData);
            }

            if(selectedCustomLayers){
                let selectedCustomData = selectedCustomLayers;
                let _selectedCustomData = [...selectedCustomData,...customData];
                setCustomSelectedLayers([..._selectedCustomData]);
            }else{
                setCustomSelectedLayers(customData);
            }

            let tempMapdata = mapdata;
            if(tempMapdata.custom){
                let currentCustomData = tempMapdata.custom;
                tempMapdata.custom = [...currentCustomData, ...customData];
                setMapdata({...tempMapdata});
            }else{
                tempMapdata.custom = customData;
                setMapdata({...tempMapdata});
            }

        });
    }

    return(

            <div style={{position: "relative"}}>
            <Toast ref={toast} />
            <Dialog
                header="Browse Datasets"
                footer={renderDatasetSelectorFooter()}
                visible={datasetSelector}
                onHide={onHide}
                modal={false}
                position='top-left'
                maximizable
            >
                <TabView>
                    <TabPanel header="Soil Data">
                        <DataTable value={soilMetadata}
                                   className="p-datatable-customers"
                                   selection={selectedSoils}
                                   onSelectionChange={e => setSelectedSoils(e.value)}
                                   rowHover paginator
                                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                   rowsPerPageOptions={[10,25,50]}
                                   rows={10}
                                   header={headerSoil}
                        >
                            <Column
                                selectionMode="multiple"
                                selectionAriaLabel="name"
                                headerStyle={{ width: '3em' }}>
                            </Column>
                            <Column
                                field="provider"
                                header="Provider"
                                filterField="provider"
                                sortable
                                filter filterPlaceholder="Search by Provider"
                            ></Column>
                            <Column
                                field="variable"
                                header="Variable"
                                filterField="variable"
                                sortable
                                filter filterPlaceholder="Search by Variable"
                            >
                            </Column>
                            <Column
                                field="unit"
                                header="Unit"
                                filterField="unit"
                                sortable
                                filter filterPlaceholder="Search by Unit"
                            >
                            </Column>
                            <Column
                                field="depth"
                                header="Depth"
                                filterField="depth"
                                sortable
                                filter filterPlaceholder="Search by Depth"
                            >
                            </Column>
                            <Column
                                field="from"
                                header="From"
                                filterField="from"
                                sortable
                                filter filterPlaceholder="Search by Date"
                            >
                            </Column>
                            <Column
                                field="to"
                                header="To"
                                filterField="to"
                                sortable
                                filter filterPlaceholder="Search by Date"
                            >
                            </Column>
                            <Column field="resolution"
                                    header="Resolution" sortable
                                    filterField="resolution"
                                    filter filterPlaceholder="Search by Resolution"
                            ></Column>
                            <Column field="license" header="License" sortable
                                    filterField="license"
                                    filter filterPlaceholder="Search by License"
                            ></Column>
                            <Column headerStyle={{ width: '4rem', textAlign: 'center' }}
                                    bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                                    body={actionBodyTemplate}
                            />
                        </DataTable>
                    </TabPanel>
                    <TabPanel header="Production Data">
                        <DataTable value={cropMetadata}
                                   className="p-datatable-customers"
                                   selection={selectedSoils}
                                   onSelectionChange={e => setSelectedSoils(e.value)}
                                   rowHover paginator
                                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                   rowsPerPageOptions={[10,25,50]}
                                   rows={10}
                                   header={headerCrop}
                        >
                            <Column
                                selectionMode="multiple"
                                selectionAriaLabel="name"
                                headerStyle={{ width: '3em' }}>
                            </Column>
                            <Column
                                field="title"
                                header="Title"
                                filterField="title"
                                sortable
                                filter filterPlaceholder="Search by Layer"
                            ></Column>
                            <Column
                                field="variable"
                                header="Variable"
                                filterField="variable"
                                sortable
                                filter filterPlaceholder="Search by Variable"
                            >
                            </Column>
                            <Column
                                field="variety"
                                header="Variety"
                                filterField="variety"
                                sortable
                                filter filterPlaceholder="Search by Variety"
                            >
                            </Column>
                            <Column
                                field="species"
                                header="Species"
                                filterField="species"
                                sortable
                                filter filterPlaceholder="Search by Species"
                            >
                            </Column>
                            <Column
                                field="technology"
                                header="Technology"
                                filterField="technology"
                                sortable
                                filter filterPlaceholder="Search by Technology"
                            >
                            </Column>
                            <Column
                                field="from"
                                header="From"
                                filterField="from"
                                sortable
                                filter filterPlaceholder="Search by Date"
                            >
                            </Column>
                            <Column
                                field="to"
                                header="To"
                                filterField="to"
                                sortable
                                filter filterPlaceholder="Search by Date"
                            >
                            </Column>
                            <Column field="resolution"
                                    header="Resolution" sortable
                                    filterField="resolution"
                                    filter filterPlaceholder="Search by Resolution"
                            ></Column>
                            <Column field="license" header="License" sortable
                                    filterField="license"
                                    filter filterPlaceholder="Search by License"
                            ></Column>
                            <Column headerStyle={{ width: '4rem', textAlign: 'center' }}
                                    bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                                    body={actionBodyTemplate}
                            />
                        </DataTable>
                    </TabPanel>
                    <TabPanel header="Climate Data">
                        <DataTable value={climateMetadata}
                                   className="p-datatable-customers"
                                   selection={selectedSoils}
                                   onSelectionChange={e => setSelectedSoils(e.value)}
                                   rowHover paginator
                                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                   rowsPerPageOptions={[10,25,50]}
                                   rows={10}
                                   header={headerClimate}
                        >
                            <Column
                                selectionMode="multiple"
                                selectionAriaLabel="name"
                                headerStyle={{ width: '3em' }}>
                            </Column>
                            <Column
                                field="title"
                                header="Title"
                                filterField="title"
                                sortable
                                filter filterPlaceholder="Search by Layer"
                            ></Column>
                            <Column
                                field="variable"
                                header="Variable"
                                filterField="variable"
                                sortable
                                filter filterPlaceholder="Search by Variable"
                            >
                            </Column>
                            <Column
                                field="climateScenario"
                                header="Climate Scenario"
                                filterField="climateScenario"
                                sortable
                                filter filterPlaceholder="Search by Climate Scenario"
                            >
                            </Column>
                            <Column
                                field="model"
                                header="Model"
                                filterField="model"
                                sortable
                                filter filterPlaceholder="Search by Model"
                            >
                            </Column>
                            <Column
                                field="month"
                                header="Month"
                                filterField="month"
                                sortable
                                filter filterPlaceholder="Search by Month"
                            >
                            </Column>
                            <Column
                                field="from"
                                header="From"
                                filterField="from"
                                sortable
                                filter filterPlaceholder="Search by Date"
                            >
                            </Column>
                            <Column
                                field="to"
                                header="To"
                                filterField="to"
                                sortable
                                filter filterPlaceholder="Search by Date"
                            >
                            </Column>
                            <Column field="resolution"
                                    header="Resolution" sortable
                                    filterField="resolution"
                                    filter filterPlaceholder="Search by Resolution"
                            ></Column>
                            <Column field="license" header="License" sortable
                                    filterField="license"
                                    filter filterPlaceholder="Search by License"
                            ></Column>
                            <Column headerStyle={{ width: '4rem', textAlign: 'center' }}
                                    bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                                    body={actionBodyTemplate}
                            />
                        </DataTable>
                    </TabPanel>
                </TabView>
            </Dialog>

            <Dialog
                header="Control Panel"
                visible={layerPanel}
                onHide={onHideLayerPanel}
                modal={false}
                position='bottom-right'
                maximizable
            >

                <Panel header="Custom Polygons" toggleable style={{width: '50vw'}} className="p-mt-4">
                    <DataTable
                        value={customLayers}
                        dataKey="id"
                        className="p-datatable-customers p-datatable-sm"
                        autoLayout={true}
                        stripedRows
                        selectionMode="checkbox"
                        selection={selectedCustomLayers}
                        onSelectionChange={e => loadCustomLayers(e.value)}
                    >
                        <Column selectionMode="multiple" style={{width:'2em'}}/>
                        <Column
                            field="title"
                            header="Layer"
                        />
                        <Column
                            key="opacity"
                            field="opacity"
                            header="Opacity"
                            body={(data,props) => opacityCustomSlider(data,props)}
                        />
                        <Column field="color"
                                header="Color" body={selectColor} />
                        <Column body={customPolygonActionBodyTemplate} />

                    </DataTable>
                </Panel>

                <Panel header="Layers" toggleable style={{width: '50vw'}} className="p-mt-4">
                    <DataTable
                        value={layers}
                        dataKey="id"
                        className="p-datatable-customers p-datatable-sm"
                        autoLayout={true}
                        stripedRows
                        rowExpansionTemplate={rowExpansionTemplate}
                        expandedRows={expandedLayers}
                        onRowToggle={(e) => setExpandedLayers(e.data)}
                        selectionMode="checkbox"
                        selection={selectedLayers}
                        onSelectionChange={e => loadLayers(e.value)}
                        onRowReorder={onRowReorder}
                    >

                        <Column expander headerStyle={{ width: '2rem' }} />
                        <Column selectionMode="multiple" style={{width:'2em'}}/>
                        <Column
                            field="title"
                            header="Layer"
                        />
                        <Column
                            key="opacity"
                            field="opacity"
                            header="Opacity"
                            body={(data,props) => opacitySlider(data,props)}
                        />
                        <Column body={layerActionBodyTemplate} />

                        <Column rowReorder rowReorderIcon="fad fa-arrows" style={{width: '2em'}} />

                    </DataTable>
                </Panel>

                <Panel header="Basemaps" toggleable style={{width: '50vw'}} className="p-mt-4">
                    <DataTable
                        value={basemaps}
                        dataKey="id"
                        className="p-datatable-customers p-datatable-sm"
                        autoLayout={true}
                        stripedRows
                        selectionMode="radiobutton"
                        selection={selectedBasemap}
                        onSelectionChange={e => loadBasemap(e.value)}
                    >

                        <Column selectionMode="single" style={{width:'2em'}}/>
                        <Column field="thumbnail" header="Thumbnail" body={imageBodyTemplate}></Column>
                        <Column
                            field="title"
                            header="Title"
                        />

                    </DataTable>
                </Panel>
            </Dialog>

            <Dialog
                header="Boxplot"
                visible={boxplotViewer}
                onHide={onHideBoxplot}
                modal={false}
                position='top-right'
            >
                <Boxplot chartId="stats" chartHeight="50vh" data={statistics}/>
            </Dialog>


            <Dialog
                header="Upload Custom Polygon"
                visible={uploadCustomPolygon}
                onHide={onHideUploadCustomPolygon}
                modal={false}
                position='top-right'
                maximizable
            >

                <FileUpload ref={fileUploadRef}
                            name="demo[]"
                            multiple accept="geojson/*" maxFileSize={1000000}
                            onUpload={onTemplateUpload}
                            onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                            headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                            customUpload uploadHandler={customPolygonUploader}
                            chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />

            </Dialog>

            <Dialog modal={true}  style={{ width: '100vw' }}
                    visible={blockMap}
                    draggable={false} closable={false}
                    showHeader={false}
                    maximized={false}
                    position="top"
            >
                <div className="p-mt-2">
                    <ProgressBar mode="indeterminate"  style={{ width: '95vw' }}></ProgressBar>
                </div>

            </Dialog>

            <div className="p-col">
                <div className="card">
                    <Map action={action} data={mapdata} layerAction={layerAction}/>
                </div>
            </div>
        </div>
    )
}
